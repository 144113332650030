import React from "react";
import { PropTypes } from "prop-types";

export default function Pace({ pace }) {
    return (
        <option className="pace-literal" value={pace}>
            {pace}
        </option>
    );
}

Pace.propType = {
    pace: PropTypes.string.isRequired
};
