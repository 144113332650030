export function tryConvertToMinPerUnit(pace, converterFn) {
    const timeInSeconds = tryConvertInSeconds(pace, converterFn);

    return printableTimeNoLeadingZeros(timeInSeconds);
}

export function tryConvertInSeconds(pace, converterFn) {
    const [minutes, seconds] = pace.split(":");

    const paceInSeconds = parseInt(minutes, 10) * 60 + parseInt(seconds, 10);

    return converterFn(paceInSeconds);
}

export function printableTimeNoLeadingZeros(timeInSeconds) {
    const { timeHours, timeMinutes, timeSeconds } = getPrintableTime(
        timeInSeconds
    );
    return (
        printHours(timeHours) +
        timeMinutes +
        ":" +
        timeWithLeadingZero(timeSeconds)
    );
}

export function getPrintableTime(timeInSeconds) {
    let timeHours = Math.floor(timeInSeconds / (60 * 60));
    let timeMinutes = Math.floor((timeInSeconds - timeHours * 60 * 60) / 60);
    let timeSeconds = Math.round(timeInSeconds % 60);

    if (timeSeconds === 60) {
        timeSeconds = 0;
        timeMinutes += 1;
    }
    if (timeMinutes === 60) {
        timeMinutes = 0;
        timeHours += 1;
    }
    return { timeHours, timeMinutes, timeSeconds };
}

function printHours(timeHours) {
    return `${timeHours > 0 ? timeHours + ":" : ""}`;
}

function timeWithLeadingZero(time) {
    return `${time < 10 ? "0" + time : time}`;
}

export function printableTime(timeInSeconds) {
    const { timeHours, timeMinutes, timeSeconds } = getPrintableTime(
        timeInSeconds
    );
    return (
        printHours(timeHours) +
        timeWithLeadingZero(timeMinutes) +
        ":" +
        timeWithLeadingZero(timeSeconds)
    );
}
