import React from "react";
import CookieBanner from "../CookieBanner/CookieBanner";
import { Link } from "react-router-dom";

export default function Footer({ appVersion }) {
    return (
        <footer>
            {process.env.REACT_APP_COOKIES_FEATURE === "ON" && <CookieBanner />}
            {process.env.REACT_APP_COOKIES_FEATURE === "ON" && (
                <nav>
                    <ul>
                        <li>
                            <Link to="/cookie-policy">Cookie Policy</Link>
                        </li>
                    </ul>
                </nav>
            )}
            <div className={"app-version"}>{appVersion}</div>
        </footer>
    );
}
