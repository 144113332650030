import React from "react";

export default function RaceTime({
    raceTime,
    raceName,
    isHeader,
    visible,
    onClick
}) {
    return (
        <div
            className={`race${isHeader ? " race-header" : ""}${
                visible ? " visible" : ""
            }`}
            onClick={onClick}
        >
            <h3 className="race__title">{raceName}</h3>
            <div className="race__time">{raceTime}</div>
        </div>
    );
}
