import React from "react";
import { Link } from "react-router-dom";

function CookiePolicy() {
    return (
        <div className="page">
            <Link to={"/"}>Back to Running Pace</Link>
            <h1>Cookies</h1>
            <small>Last updated: 10/05/2020</small>
            <p>
                To make this site work properly, we sometimes place small data
                files called cookies on your device. Most big websites do this
                too.
            </p>
            <p>
                We may use cookies, web beacons, tracking pixels, and other
                tracking technologies when you visit our website, including any
                other media form, media channel, mobile website, or mobile
                application related or connected thereto (collectively, the
                "Site") to help customize the Site and improve your experience.
            </p>
            <p>
                We reserve the right to make changes to this Cookie Policy at
                any time and for any reason. We will alert you about any changes
                by updating the "Last Updated" date of this Cookie Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Cookie Policy on the Site, and you waive the
                right to receive specific notice of each such change or
                modification.
            </p>

            <p>
                You are encouraged to periodically review this Cookie Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Cookie Policy by your
                continued use of the Site after the date such revised Cookie
                Policy is posted.
            </p>

            <h2>What are cookies?</h2>
            <p>
                A cookie is a small text file that a website saves on your
                computer or mobile device when you visit the site. It enables
                the website to remember your actions and preferences (such as
                login, language, font size and other display preferences) over a
                period of time, so you don’t have to keep re-entering them
                whenever you come back to the site or browse from one page to
                another.
            </p>

            <h2>How do we use cookies?</h2>

            <p>Analytics Cookies</p>
            <p>
                Analytics cookies monitor how users reached the Site, and how
                they interact with and move around once on the Site. These
                cookies let us know what features on the Site are working the
                best and what features on the Site can be improved.
            </p>

            <p>Advertising Cookies </p>
            <p>
                Advertising cookies are placed on your computer by advertisers
                and ad servers in order to display advertisements that are most
                likely to be of interest to you. These cookies allow advertisers
                and ad servers to gather information about your visits to the
                Site and other websites, alternate the ads sent to a specific
                computer, and track how often an ad has been viewed and by whom.
                These cookies are linked to a computer and do not gather any
                personal information about you.
            </p>
            <p>First-party cookies</p>
            <p>
                The Site uses cookies to remember your preferences such as: if
                you agreed to our use of cookies on this site. These can be
                permanent or temporary. These are necessary cookies, without
                which the Site won’t work properly or be able to provide certain
                features and functionalities. Some of these may be manually
                disabled in your browser, but may affect the functionality of
                the Site.
            </p>

            <p>Third-Party Cookies</p>

            <p>
                Third-party cookies may be place on your computer when you visit
                the Site by companies that run certain services we offer. These
                cookies allow the third parties to gather and track certain
                information about you. These cookies can be manually disabled in
                your browser.
            </p>

            <h2>Control of cookies</h2>
            <p>
                How to control cookies You can control and/or delete cookies as
                you wish – for details, see aboutcookies.org. You can delete all
                cookies that are already on your computer and you can set most
                browsers to prevent them from being placed. If you do this,
                however, you may have to manually adjust some preferences every
                time you visit a site and some services and functionalities may
                not work.
            </p>
            <p>
                Most browsers are set to accept cookies by default. However, you
                can remove or reject cookies in your browser&rsquo;s settings.
                Please be aware that such action could affect the availability
                and functionality of the Site.
            </p>
            <p>
                For more information on how to control cookies, check your
                browser or device&rsquo;s settings for how you can control or
                reject cookies, or visit the following links:
            </p>
            <p>
                <a
                    href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                    rel="nofollow"
                >
                    Apple Safari
                </a>
            </p>
            <p>
                <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                    rel="nofollow"
                >
                    Google Chrome
                </a>
            </p>
            <p>
                <a
                    href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
                    rel="nofollow"
                >
                    Microsoft Edge
                </a>
            </p>
            <p>
                <a
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                    rel="nofollow"
                >
                    Microsoft Internet Explorer
                </a>
            </p>
            <p>
                <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                    rel="nofollow"
                >
                    Mozilla Firefox
                </a>
            </p>
            <p>
                <a href="https://help.opera.com/en/latest/" rel="nofollow">
                    Opera
                </a>
            </p>
            <h2>Other tracking Technologies</h2>
            <p>
                In addition to cookies, we may use web beacons, pixel tags, and
                other tracking technologies on the Site to help customize the
                Site and improve your experience. A &ldquo;web beacon&rdquo; or
                &ldquo;pixel tag&rdquo; is tiny object or image embedded in a
                web page or email. They are used to track the number of users
                who have visited particular pages and viewed emails, and acquire
                other statistical data. They collect only a limited set of data,
                such as a cookie number, time and date of page or email view,
                and a description of the page or email on which they reside. Web
                beacons and pixel tags cannot be declined. However, you can
                limit their use by controlling the cookies that interact with
                them.
            </p>
        </div>
    );
}

export default CookiePolicy;
