export const NO_PACE_TIME_PLACEHOLDER = "--:--";
export const MARATHON_DISTANCE_KM = 42.195;
export const HALF_MARATHON_DISTANCE_KM = 21.0975;
export const SPARTATHLON_KM = 245.3;
export const ONE_HUNDRED_MILES_IN_KM = 160.9344;
export const FIFTY_MILES_IN_KM = 80.4672;
export const ONE_HUNDRED_60_MILES_IN_KM = 257.495;
export const ONE_MILE_IN_KM = 1.609344;
export const ONE_KM_IN_MILES = 0.6213712;

export const MARATHON_TIME_TITLE = "Marathon";
export const HALF_MARATHON_TIME_TITLE = "Half Marathon";
export const TEN_K_TIME_TITLE = "10k";
export const TEN_MILES_TIME_TITLE = "10miles";
export const FIVE_K_TIME_TITLE = "5k";
export const SPARTATHLON_TIME_TITLE = "Spartathlon (245.3km)";
export const ONE_HUNDRED_KM_TIME_TITLE = "100km";
export const ONE_HUNDRED_MILES_TIME_TITLE = "100miles";
export const FIFTY_KM_TIME_TITLE = "50km";
export const FIFTY_MILES_TIME_TITLE = "50miles";
export const ONE_HUNDRED_60_MILES_TIME_TITLE = "160miles";

export const UNIT_MIN_KM = "min/km";
export const UNIT_MIN_MILE = "min/mile";

export const DEFAULT_START_PACE = "4:00";

export const COOKIE_BANNER_STORAGE_KEY = "cp-agree-v1";
