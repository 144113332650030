import React from "react";
import Pace from "./Pace";
import { NO_PACE_TIME_PLACEHOLDER } from "../../constants";
import PropTypes from "prop-types";

export default function PaceSelector({
    paces,
    paceUnit,
    approx,
    selectedPace,
    handlePaceChange
}) {
    let paceOptions = [
        <Pace pace={NO_PACE_TIME_PLACEHOLDER} key={NO_PACE_TIME_PLACEHOLDER} />
    ];
    if (paces && paces.length >= 1) {
        paceOptions = paces.map(pace => {
            return <Pace key={pace} pace={pace} />;
        });
    }

    function onChangeHandler(e) {
        handlePaceChange(e.target.value);
    }

    return (
        <div className={`pace-selector ${approx ? "approx" : ""}`}>
            <small className={"approx-sign"}>&asymp;</small>
            <select value={selectedPace} onChange={onChangeHandler}>
                {paceOptions}
            </select>
            <div className="pace-unit">{paceUnit}</div>
        </div>
    );
}

PaceSelector.propTypes = {
    paces: PropTypes.array.isRequired,
    paceUnit: PropTypes.string.isRequired,
    handlePaceChange: PropTypes.func.isRequired
};
