import React, { Component } from "react";
import Dashboard from "./Dashboard";
import Footer from "./Footer/Footer";
import { UNIT_MIN_KM, UNIT_MIN_MILE } from "../constants";
import { getPacesMinKmFromRange, getPacesInMinMiles } from "../pace/paces";
import packageInfo from "../../package.json";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookiePolicy from "./CookiePolicy";

const pacesMinKm = {
    unit: UNIT_MIN_KM,
    paces: getPacesMinKmFromRange(1, 15)
};

const pacesMinMile = {
    unit: UNIT_MIN_MILE,
    paces: getPacesInMinMiles(pacesMinKm.paces)
};

class App extends Component {
    render() {
        return (
            <Router>
                <div className="pace-calculator">
                    <Routes>
                        <Route
                            exact
                            path={"/"}
                            element={
                                <Dashboard
                                    pacesMinKm={pacesMinKm}
                                    pacesMinMile={pacesMinMile}
                                />
                            }
                        />
                        {process.env.REACT_APP_COOKIES_FEATURE === "ON" && (
                            <Route
                                path={"/cookie-policy"}
                                element={CookiePolicy}
                            />
                        )}
                    </Routes>

                    <Footer appVersion={packageInfo.version} />
                </div>
            </Router>
        );
    }
}

export default App;
