import {
    FIFTY_KM_TIME_TITLE,
    FIFTY_MILES_IN_KM,
    FIFTY_MILES_TIME_TITLE,
    FIVE_K_TIME_TITLE,
    HALF_MARATHON_DISTANCE_KM,
    HALF_MARATHON_TIME_TITLE,
    MARATHON_DISTANCE_KM,
    MARATHON_TIME_TITLE,
    ONE_HUNDRED_KM_TIME_TITLE,
    ONE_HUNDRED_MILES_IN_KM,
    ONE_HUNDRED_MILES_TIME_TITLE,
    TEN_K_TIME_TITLE,
    TEN_MILES_TIME_TITLE
} from "../constants";

export function getRaces() {
    return [
        {
            name: "400m",
            distanceKm: 0.4
        },
        {
            name: "1k",
            distanceKm: 1
        },
        {
            name: FIVE_K_TIME_TITLE,
            distanceKm: 5
        },
        {
            name: TEN_K_TIME_TITLE,
            distanceKm: 10
        },
        {
            name: TEN_MILES_TIME_TITLE,
            distanceKm: 16.09344
        },
        {
            name: HALF_MARATHON_TIME_TITLE,
            distanceKm: HALF_MARATHON_DISTANCE_KM
        },
        {
            name: MARATHON_TIME_TITLE,
            distanceKm: MARATHON_DISTANCE_KM
        },
        {
            name: FIFTY_KM_TIME_TITLE,
            distanceKm: 50
        },
        {
            name: FIFTY_MILES_TIME_TITLE,
            distanceKm: FIFTY_MILES_IN_KM
        },
        {
            name: ONE_HUNDRED_KM_TIME_TITLE,
            distanceKm: 100
        },
        {
            name: ONE_HUNDRED_MILES_TIME_TITLE,
            distanceKm: ONE_HUNDRED_MILES_IN_KM
        }
    ];
}
