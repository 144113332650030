import React, { useState } from "react";
import Races from "./Races/Races";
import PaceSelector from "./PaceSelector/PaceSelector";
import { UNIT_MIN_KM, UNIT_MIN_MILE, DEFAULT_START_PACE } from "../constants";
import PropTypes from "prop-types";
import {
    tryConvertInSeconds,
    tryConvertToMinPerUnit
} from "../race/raceTimeProvider";
import { toSecondsPerMile, toSecondsPerKm } from "../pace/converters";
import { getRaces } from "../race/races";

const races = getRaces();

function Dashboard({ pacesMinKm, pacesMinMile }) {
    const [pace, setPace] = useState({
        unit: UNIT_MIN_KM,
        pace: DEFAULT_START_PACE
    });

    const paceMinMile =
        pace.unit === UNIT_MIN_MILE
            ? pace.pace
            : tryConvertToMinPerUnit(pace.pace, toSecondsPerMile);
    const paceMinKm =
        pace.unit === UNIT_MIN_KM
            ? pace.pace
            : tryConvertToMinPerUnit(pace.pace, toSecondsPerKm);
    const secondsPerKm =
        pace.unit === UNIT_MIN_KM
            ? tryConvertInSeconds(pace.pace, seconds => seconds)
            : tryConvertInSeconds(pace.pace, toSecondsPerKm);

    return (
        <div className="page">
            <h1>Running pace</h1>
            <p className={"text-center"}>
                Select your pace to see the race times
            </p>
            <div className="dashboard">
                <PaceSelector
                    selectedPace={paceMinKm}
                    paceUnit={UNIT_MIN_KM}
                    paces={pacesMinKm.paces}
                    approx={pace.unit !== UNIT_MIN_KM}
                    handlePaceChange={pace =>
                        setPace({ unit: UNIT_MIN_KM, pace: pace })
                    }
                />
                <PaceSelector
                    selectedPace={paceMinMile}
                    paceUnit={UNIT_MIN_MILE}
                    paces={pacesMinMile.paces}
                    approx={pace.unit !== UNIT_MIN_MILE}
                    handlePaceChange={pace =>
                        setPace({ unit: UNIT_MIN_MILE, pace: pace })
                    }
                />
                <Races secondsPerKm={secondsPerKm} races={races} />
            </div>
        </div>
    );
}

Dashboard.propTypes = {
    pacesMinKm: PropTypes.object.isRequired,
    pacesMinMile: PropTypes.object.isRequired
};

export default Dashboard;
