import React from "react";
import "./race.css";
import RaceTime from "./RaceTime";
import { printableTime } from "../../race/raceTimeProvider";

export default function Races({ secondsPerKm, races }) {
    function distanceTime(distanceKm) {
        return secondsPerKm => distanceKm * secondsPerKm;
    }

    return (
        <div className="races">
            {races.map(race => {
                return (
                    <RaceTime
                        key={race.name}
                        raceName={race.name}
                        raceTime={printableTime(
                            distanceTime(race.distanceKm)(secondsPerKm)
                        )}
                    />
                );
            })}
        </div>
    );
}
