import {
    printableTimeNoLeadingZeros,
    tryConvertInSeconds
} from "../race/raceTimeProvider";
import { toSecondsPerMile } from "./converters";

export function getPacesMinKmFromRange(startMinute, endMinuteInclusive) {
    const minuteDigits = getDigitsToDisplayAsMinute(
        startMinute,
        endMinuteInclusive
    );

    return minuteDigits
        .map(getDisplayTimesForOneMinute)
        .reduce((acc, arr) => acc.concat([...arr]), []);
}

function getDigitsToDisplayAsMinute(startMinuteDigit, endMinuteInclusive) {
    return [...Array(endMinuteInclusive + 1).keys()].filter(
        minuteDigit => minuteDigit >= startMinuteDigit
    );
}

function getDisplayTimesForOneMinute(minuteDigit) {
    return [...Array(60)].map(
        (value, secondsDigit) =>
            `${minuteDigit}:${
                secondsDigit < 10 ? `0${secondsDigit}` : secondsDigit
            }`
    );
}

export function getPacesInMinMiles(paces) {
    const startSeconds = Math.round(
        tryConvertInSeconds(paces[0], toSecondsPerMile)
    );
    const endSeconds = Math.round(
        tryConvertInSeconds(paces[paces.length - 1], toSecondsPerMile)
    );
    const seconds = [];
    for (let i = startSeconds; i <= endSeconds; i++) {
        seconds.push(i);
    }

    return seconds.map(printableTimeNoLeadingZeros);
}
