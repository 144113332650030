export const WebAppStorage = function(global) {
    return {
        storageAvailable: function() {
            let storage;
            let x = "__storage_test__";
            try {
                storage = global["localStorage"];
                storage.setItem(x, x);
                storage.removeItem(x);
                return true;
            } catch (e) {
                return (
                    e instanceof DOMException &&
                    (e.code === 22 ||
                        e.code === 1014 ||
                        e.name === "QuotaExceededError" ||
                        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                    storage.length !== 0
                );
            }
        },
        setItem: function(name, value) {
            try {
                if (this.storageAvailable()) {
                    global.localStorage.setItem(name, value);
                }
            } catch (e) {
                return null;
            }
        },
        getItem: function(name) {
            if (this.storageAvailable()) {
                return global.localStorage.getItem(name);
            }
        },
        removeItem: function(name) {
            if (this.storageAvailable()) {
                global.localStorage.removeItem(name);
            }
        }
    };
};

export default WebAppStorage(window);
